import React from "react";

const Table = ({
  columns,
  data,
  onRowClick = null, //makes the onRowClick prop optional
  getTableProps,
  getTableBodyProps,
  headerGroups,
  prepareRow,
  canPreviousPage, // New prop
  canNextPage, // New prop
  previousPage, // New prop
  nextPage, // New prop
  pageSize, // New prop
  setPageSize, // New prop
  totalFilteredRows, // New prop total filtered rows
}) => {
  return (
    <div className="mt-4">
      <div className="overflow-auto max-h-[calc(100vh-28vh)] min-h-[calc(100vh-28vh)] shadow shadow-black rounded-lg">
        <table className="table-auto min-w-full" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, columnIndex) => (
                  <th
                    className={`px-4 py-2 bg-joule text-white sticky top-0 z-10 text-left ${
                      columnIndex === 0 ? "rounded-tl-lg" : ""
                    } ${
                      columnIndex === headerGroup.headers.length - 1
                        ? "rounded-tr-lg"
                        : ""
                    }`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {/* Table body containing data rows */}
          <tbody {...getTableBodyProps()}>
            {data.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() =>
                    onRowClick &&
                    typeof onRowClick === "function" &&
                    onRowClick(row)
                  } // Check if onRowClick is a function
                  className="hover:bg-gray-200"
                >
                  {row.cells.map((cell) => (
                    <td
                      className="px-4 py-2 text-left"
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* Pagination Controls */}
      <div className="pagination flex items-center justify-between mt-4">
        {/* Previous & Next page buttons */}
        <div className="flex items-center">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className={`mr-2 px-4 py-2 rounded border ${
              canPreviousPage
                ? "hover:bg-gray-200"
                : "opacity-50 cursor-not-allowed"
            }`}
          >
            Previous
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className={`px-4 py-2 rounded border ${
              canNextPage
                ? "hover:bg-gray-200"
                : "opacity-50 cursor-not-allowed"
            }`}
          >
            Next
          </button>
        </div>

        {/* Displaying filtered row count */}
        <div className="text-black font-semibold">
          Showing {data.length} out of {totalFilteredRows} rows
        </div>

        {/* Selector for number of rows per page */}
        <div className="flex items-center">
          <label className="mr-2" htmlFor="rowsPerPage">
            Rows per page:
          </label>
          <select
            id="rowsPerPage"
            className="border rounded p-2"
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map((pageSizeOption) => (
              <option key={pageSizeOption} value={pageSizeOption}>
                {pageSizeOption}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Table;
