import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import axios from "axios";
import Table from "../components/tableComponent";
import { DateCell } from "../components/calendarComp";
import ServiceCell from "../components/serviceRegistration";

/**
 * `EditableSerialNumberCell` Component
 *
 * Displays a text input for editing the serial number of a booking.
 * The confirm button is shown only when the user changes the serial number.
 * When the confirm button is clicked, the serial number is updated in the database.
 */

const EditableSerialNumberCell = ({ row, updateField }) => {
  const [value, setValue] = useState(row.original.serialNumber);
  const [editMode, setEditMode] = useState(false);
  const inputRef = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setEditMode(false);
        setValue(row.original.serialNumber); // Revert to original value
      }
    },
    [row.original.serialNumber]
  );

  useEffect(() => {
    if (editMode) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editMode, handleClickOutside]);

  const handleInputChange = (e) => {
    setValue(e.target.value);
    setEditMode(e.target.value !== row.original.serialNumber);
  };

  const handleConfirmEdit = async () => {
    await updateField(row.original._id, "serialNumber", value);
    setEditMode(false);
  };

  return (
    <div className="flex items-center" ref={inputRef}>
      <input
        type="text"
        value={value}
        onChange={handleInputChange}
        className="border p-1 rounded mr-2 w-1/2"
        onFocus={() => setEditMode(true)}
      />
      {editMode && (
        <button
          onClick={handleConfirmEdit}
          className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline"
        >
          Confirm
        </button>
      )}
    </div>
  );
};

/**
 * `BookingsTable` Component
 *
 * Displays a sortable, filterable table containing booking information.
 */

function multiSelectFilter(rows, columnIds, filterValue) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) => filterValue.includes(row.values[columnIds]));
}

const BookingsTable = () => {
  const [data, setData] = useState([]); // Holds the table data
  const [filterInput, setFilterInput] = useState(""); // User's input for filtering
  const [loading, setLoading] = useState(true); // Loading state for async operations
  const [filterCategory, setFilterCategory] = useState(""); // To hold filter type, e.g. "type", "bookingDate" etc.
  const [filterOutClosed, setFilterOutClosed] = useState(true); // State to track the checkbox
  const [modalData, setModalData] = useState(null); // Data for the row whose modal is open

  // Function to toggle the modal visibility and set the data for the modal
  const toggleModal = useCallback((data = null) => {
    if (data) {
      setModalData({ ...data, source: "bookings" });
    } else {
      setModalData(null); // This should hide the modal
    }
  }, []);

  const updateField = useCallback(
    async (id, field, value) => {
      try {
        const response = await axios.put(`/api/bookings/${id}`, {
          [field]: value,
        });
        if (response.status === 200) {
          const updatedData = data.map((item) =>
            item._id === id ? { ...item, [field]: value } : item
          );
          setData(updatedData);
        }
      } catch (error) {
        console.error(`Error updating ${field}:`, error);
      }
    },
    [data]
  ); // data is a dependency here because it's used inside the function

  const updateInventoryOperationalStatus = useCallback(
    async (serialNumber, newStatus) => {
      try {
        await axios.put(`/api/inventory/${serialNumber}/operationalStatus`, {
          operationalStatus: newStatus,
        });
      } catch (error) {
        console.error("Error updating inventory operational status:", error);
      }
    },
    []
  );

  // Function to mark a booking as repaired, this function is passed to the modal "ServiceRegistration", and the ServiceCell component
  const markAsRepaired = async (id) => {
    await updateField(id, "bookingStatus", "ready for customer");
  };

  //Function to delete a service booking
  const deleteBooking = useCallback(
    async (id) => {
      try {
        const response = await axios.delete(`/api/bookings/${id}`);
        if (response.status === 200) {
          const updatedData = data.filter((item) => item._id !== id);
          setData(updatedData);
        }
      } catch (error) {
        console.error("Error deleting booking:", error);
      }
    },
    [data]
  );

  // Column definitions for the table
  const columns = useMemo(
    () => [
      // Each object defines a column header and the data accessor for the corresponding data.
      {
        Header: "Serial Number",
        accessor: "serialNumber",
        Cell: ({ row }) => (
          <EditableSerialNumberCell row={row} updateField={updateField} />
        ),
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Booking Date",
        accessor: "bookingDate",
        Cell: ({ row }) => (
          <DateCell
            date={row.original.bookingDate}
            updateField={updateField}
            id={row.original._id}
            fieldToUpdate={"bookingDate"}
          />
        ),
      },
      {
        Header: "Shipping In",
        accessor: "deliveryTypeFromCustomer",
      },
      {
        Header: "Customer Name",
        accessor: (row) => `${row.firstName} ${row.lastName}`,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Number",
        accessor: "number",
      },
      {
        Header: "Note",
        accessor: "note",
        Cell: ({ value }) => (
          <div
            style={{
              width: "100px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            title={value}
          >
            {value}
          </div>
        ),
      },
      {
        Header: "Return date",
        accessor: "returnDate",
      },
      {
        Header: "Shipping Out",
        accessor: "deliveryTypeToCustomer",
      },
      {
        Header: "Service status",
        accessor: "bookingStatus",
        Cell: ({ row }) => <span>{row.original.bookingStatus}</span>,
        filter: multiSelectFilter,
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ row }) => {
          const handleActionClick = async () => {
            let newStatus = "";
            let operationalStatus = "";
            try {
              const {
                _id,
                serialNumber,
                bookingStatus,
                deliveryTypeFromCustomer,
                deliveryTypeToCustomer,
                returnDate,
              } = row.original;

              switch (bookingStatus) {
                case "new":
                  newStatus = "received";
                  operationalStatus = "repair-booked";

                  // Check if the deliveryTypeFromCustomer is "nydalen" and set bookingDate to today
                  if (deliveryTypeFromCustomer === "nydalen") {
                    await updateField(
                      _id,
                      "bookingDate",
                      new Date().toISOString()
                    );
                  }

                  await updateField(_id, "bookingStatus", newStatus);
                  await updateInventoryOperationalStatus(
                    serialNumber,
                    operationalStatus
                  );
                  break;

                case "received":
                  toggleModal(row.original);
                  return;

                case "ready for customer":
                  newStatus = "returned to customer";
                  operationalStatus = "with customer";

                  // If no deliveryTypeToCustomer and returnDate, set defaults
                  if (!deliveryTypeToCustomer && !returnDate) {
                    await updateField(_id, "deliveryTypeToCustomer", "nydalen");
                    await updateField(
                      _id,
                      "returnDate",
                      new Date().toISOString()
                    );
                  }

                  await updateField(_id, "bookingStatus", newStatus);
                  await updateInventoryOperationalStatus(
                    serialNumber,
                    operationalStatus
                  );
                  break;

                default:
                  return; // No action for other statuses
              }
            } catch (error) {
              console.error("Error updating booking and inventory:", error);
            }
          };

          let buttonText = "";
          switch (row.original.bookingStatus) {
            case "new":
              buttonText = "Mark as received";
              break;
            case "received":
              buttonText = "Start repair";
              break;
            case "ready for customer":
              buttonText = "Returned to customer";
              break;
            default:
              return null; // No button for other statuses
          }
          return (
            <div className="flex items-center justify-between">
              <button
                onClick={handleActionClick}
                className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline mr-2 w-32"
              >
                {buttonText}
              </button>
              <button
                onClick={() => deleteBooking(row.original._id)}
                className="bg-red-500 hover:bg-red-700 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline"
                style={{ padding: "0.25rem 0.5rem", lineHeight: 0 }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  className="w-4 h-4"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.225 4.811a1 1 0 011.414-1.414L12 7.757l4.36-4.36a1 1 0 111.414 1.414L13.414 9.17l4.36 4.36a1 1 0 01-1.414 1.414L12 10.586l-4.36 4.36a1 1 0 01-1.414-1.414l4.36-4.36-4.36-4.36z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
          );
        },
      },
    ],
    [updateField, toggleModal, updateInventoryOperationalStatus, deleteBooking]
  );

  // react-table hooks for creating table instance with sorting, filtering, and pagination capabilities
  const {
    // Destructure methods and properties needed from useTable
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setFilter,
    rows,
    state: { pageSize },
    setPageSize,
  } = useTable(
    // Initial table configuration
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        sortBy: [{ id: "bookingDate", desc: false }],
        filters: [
          {
            id: "bookingStatus",
            value: ["new", "received", "ready for customer"],
          },
        ],
      },
    },
    useFilters, // Enables filtering capabilities
    useSortBy, // Enables sorting capabilities
    usePagination // Enables pagination capabilities
  );

  // Fetch bookings data when component mounts
  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get("/api/bookings");
        setData(response.data || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching bookings:", error);
        setLoading(false);
      }
    };
    fetchBookings();
  }, []);

  // Handle changes in filter input
  const handleFilterChange = (e) => {
    setFilterInput(e.target.value);
    if (filterCategory) {
      setFilter(filterCategory, e.target.value);
    }
  };

  // Filter out all closed cases
  const handleFilterOutClosedChange = (e) => {
    setFilterOutClosed(e.target.checked);
    if (e.target.checked) {
      // Apply filter to show only 'new', 'received', and 'ready for customer' bookings
      setFilter("bookingStatus", ["new", "received", "ready for customer"]);
    } else {
      // Clear the filter to show all bookings, including closed
      setFilter("bookingStatus", "");
    }
  };
  // Show loading indicator while data is being fetched
  if (loading) return <div>Loading...</div>;

  return (
    <div className="flex-grow p-4 mx-6">
      <h2 className="text-2xl font-semibold mb-4">Bookings</h2>

      {/* Filter Inputs */}
      <div className="flex justify-between mb-4 items-center">
        {/* Dropdown to select filter category/column */}
        <select
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value)}
          className="border p-2 rounded focus:outline-none focus:ring-2 focus:ring-indigo-400"
        >
          <option value="">Select Filter Category...</option>
          {columns.map((column) => (
            <option value={column.accessor} key={column.accessor}>
              {column.Header}
            </option>
          ))}
        </select>

        {/* Checkbox to filter out 'closed' services */}
        <label className="flex items-center space-x-2 bg-gray-200 px-3 py-1 rounded-lg cursor-pointer">
          <input
            type="checkbox"
            checked={filterOutClosed}
            onChange={handleFilterOutClosedChange}
            className="form-checkbox text-black rounded border-gray-300 bg-white focus:border-joule focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          />
          <span className="text-sm font-medium text-gray-700">
            Hide closed services
          </span>
        </label>
      </div>

      {/* Text input for entering filter values */}
      <input
        value={filterInput}
        onChange={handleFilterChange}
        placeholder="Enter filter value..."
        className="border p-2 mt-4 rounded w-full focus:outline-none focus:ring-2 focus:ring-indigo-400"
      />

      {/* Table section */}
      <Table
        columns={columns}
        data={page}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalFilteredRows={rows.length}
      />
      {modalData && (
        <ServiceCell
          isOpen={!!modalData}
          data={modalData}
          toggleModalVisibility={() => toggleModal()}
          source={modalData.source} // Pass the source to the modal
          markAsRepaired={markAsRepaired}
        />
      )}
    </div>
  );
};

export default BookingsTable;
