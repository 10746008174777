import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useTable, useSortBy } from "react-table";
import axios from "axios";
import ReturnConfirmationModal from "../components/returnConfirmationModal";

const BikeFlowDashboard = () => {
  const [outgoingOrdersData, setOutgoingOrdersData] = useState([]);
  const [incomingBookingsData, setIncomingBookingsData] = useState([]);
  const [outgoingBookingsData, setOutgoingBookingsData] = useState([]);
  const [incomingReturnsData, setIncomingReturnsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [returnModalData, setReturnModalData] = useState(null); // State for return modal

  const toggleReturnModal = useCallback((data = null) => {
    if (data) {
      setReturnModalData({ ...data, source: "returns" });
    } else {
      setReturnModalData(null); // This should hide the modal
    }
  }, []);

  const updateField = useCallback(
    async (id, field, value, source) => {
      try {
        let url = `/api/${source}/${id}`;
        const response = await axios.put(url, { [field]: value });
        if (response.status === 200) {
          switch (source) {
            case "orders":
              // For outgoing orders
              setOutgoingOrdersData(
                outgoingOrdersData.map((item) =>
                  item._id === id ? { ...item, [field]: value } : item
                )
              );
              break;
            case "bookings":
              if (field === "bookingStatus") {
                if (value === "received") {
                  // Remove the booking from incomingBookingsData as its status is no longer 'new'
                  setIncomingBookingsData(
                    incomingBookingsData.filter((item) => item._id !== id)
                  );
                } else if (value === "returned to customer") {
                  // Remove the booking from outgoingBookingsData as its status is no longer 'ready for customer'
                  setOutgoingBookingsData(
                    outgoingBookingsData.filter((item) => item._id !== id)
                  );
                } else {
                  // Keep the data as is for other scenarios
                  setIncomingBookingsData(
                    incomingBookingsData.map((item) =>
                      item._id === id ? { ...item, [field]: value } : item
                    )
                  );
                  setOutgoingBookingsData(
                    outgoingBookingsData.map((item) =>
                      item._id === id ? { ...item, [field]: value } : item
                    )
                  );
                }
              }
              break;

            case "returns":
              // For incoming returns
              setIncomingReturnsData(
                incomingReturnsData.map((item) =>
                  item._id === id ? { ...item, [field]: value } : item
                )
              );
              break;
            default:
              throw new Error(`Unknown source: ${source}`);
          }
        }
      } catch (error) {
        console.error(`Error updating ${field}:`, error);
      }
    },
    [
      outgoingOrdersData,
      incomingBookingsData,
      outgoingBookingsData,
      incomingReturnsData,
    ]
  );

  const markAsReturned = async (id) => {
    try {
      await updateField(id, "returnStatus", "returned", "returns");
    } catch (error) {
      console.error("Error marking as repaired:", error);
    }
  };

  //Function if marking the bike directly back in stock from the returnConfirmationModal
  const markAsRepaired = async (id) => {
    try {
      await updateField(id, "returnStatus", "repaired", "returns");
    } catch (error) {
      console.error("Error marking as repaired:", error);
    }
  };

  const updateInventoryOperationalStatus = useCallback(
    async (serialNumber, newStatus) => {
      try {
        await axios.put(`/api/inventory/${serialNumber}/operationalStatus`, {
          operationalStatus: newStatus,
        });
      } catch (error) {
        console.error("Error updating inventory operational status:", error);
      }
    },
    []
  );

  // Define columns for Outgoing Orders
  const outgoingOrdersColumns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: "serialNumber",
      },
      {
        Header: "Shipping Method",
        accessor: "orderShippingMethod",
      },
      {
        Header: "Delivery Date",
        accessor: "orderTagDate",
        Cell: ({ value }) =>
          new Date(value).toLocaleDateString("nb-NO").replace(/\//g, "."),
      },
      {
        Header: "Customer Name",
        accessor: (row) => `${row.firstName} ${row.lastName}`,
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ row }) => {
          const handleActionClick = async () => {
            let newStatus = "";
            switch (row.original.orderStatus) {
              case "ready for customer":
                newStatus = "fulfilled";
                try {
                  await axios.post(
                    `/api/circuly-orders/${row.original.orderId}/fulfill`
                  );
                  await axios.put(
                    `/api/circuly-orders/${row.original.orderId}/tag`,
                    { tag: "delivered" }
                  );
                  // Update inventory item's operational status to "With customer"
                  await updateInventoryOperationalStatus(
                    row.original.serialNumber,
                    "with customer"
                  );
                  await updateField(
                    row.original._id,
                    "orderStatus",
                    newStatus,
                    "orders"
                  );
                } catch (error) {
                  console.error("Error processing order fulfillment:", error);
                }
                break;
              default:
                break;
            }
          };

          let buttonText =
            row.original.orderStatus === "ready for customer"
              ? "Mark as fulfilled"
              : "";

          return buttonText ? (
            <button
              onClick={handleActionClick}
              className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline"
            >
              {buttonText}
            </button>
          ) : null;
        },
      },
    ],
    [updateField, updateInventoryOperationalStatus]
  );

  // Define columns for Incoming Bookings
  const incomingBookingsColumns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: "serialNumber",
      },
      {
        Header: "Shipping Method",
        accessor: "deliveryTypeFromCustomer",
      },
      {
        Header: "Booking Date",
        accessor: "bookingDate",
        Cell: ({ value }) =>
          new Date(value).toLocaleDateString("nb-NO").replace(/\//g, "."),
      },
      {
        Header: "Customer Name",
        accessor: (row) => `${row.firstName} ${row.lastName}`,
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ row }) => {
          const handleActionClick = async () => {
            if (row.original.bookingStatus === "new") {
              try {
                // Update inventory operational status to "repair-booked"
                await updateInventoryOperationalStatus(
                  row.original.serialNumber,
                  "repair-booked"
                );
                // Update booking status to "received"
                await updateField(
                  row.original._id,
                  "bookingStatus",
                  "received",
                  "bookings"
                );
              } catch (error) {
                console.error("Error in updating booking status:", error);
              }
            }
          };

          let buttonText =
            row.original.bookingStatus === "new" ? "Mark as received" : "";

          return buttonText ? (
            <button
              onClick={handleActionClick}
              className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline"
            >
              {buttonText}
            </button>
          ) : null;
        },
      },
    ],
    [updateField, updateInventoryOperationalStatus]
  );

  // Define columns for Incoming Bookings
  const outgoingBookingsColumns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: "serialNumber",
      },
      {
        Header: "Shipping Method",
        accessor: "deliveryTypeToCustomer",
      },
      {
        Header: "Return Date",
        accessor: "returnDate",
        Cell: ({ value }) =>
          new Date(value).toLocaleDateString("nb-NO").replace(/\//g, "."),
      },
      {
        Header: "Customer Name",
        accessor: (row) => `${row.firstName} ${row.lastName}`,
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ row }) => {
          const handleActionClick = async () => {
            if (row.original.bookingStatus === "ready for customer") {
              try {
                // Update inventory operational status to "with customer"
                await updateInventoryOperationalStatus(
                  row.original.serialNumber,
                  "with customer"
                );
                // Update booking status to "Returned to customer"
                await updateField(
                  row.original._id,
                  "bookingStatus",
                  "returned to customer",
                  "bookings"
                );
              } catch (error) {
                console.error("Error in updating booking status:", error);
              }
            }
          };

          let buttonText =
            row.original.bookingStatus === "ready for customer"
              ? "Returned to customer"
              : "";

          return buttonText ? (
            <button
              onClick={handleActionClick}
              className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline"
            >
              {buttonText}
            </button>
          ) : null;
        },
      },
    ],
    [updateField, updateInventoryOperationalStatus]
  );

  const incomingReturnsColumns = useMemo(
    () => [
      {
        Header: "Serial Number",
        accessor: "serialNumber",
      },
      {
        Header: "Shipping Method",
        accessor: "returnShippingMethod",
      },
      {
        Header: "Return Date",
        accessor: "returnDate",
        Cell: ({ value }) => {
          if (value === null || isNaN(new Date(value))) {
            return ""; // or "Not selected" for clarity
          }
          return new Date(value)
            .toLocaleDateString("nb-NO")
            .replace(/\//g, ".");
        },
      },
      {
        Header: "Customer Name",
        accessor: (row) => `${row.firstName} ${row.lastName}`,
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ row }) => {
          return row.original.returnStatus === "new" ? (
            <button
              onClick={() => toggleReturnModal(row.original)}
              className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline"
            >
              Mark as returned
            </button>
          ) : null;
        },
      },
    ],
    [toggleReturnModal]
  );

  //Utility to add color to the rows if date is today or older
  const compareDateToToday = (dateString) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to start of the day
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0); // Normalize the compared date

    if (date.getTime() === today.getTime()) {
      return "today";
    } else if (date < today) {
      return "past";
    } else {
      return "future";
    }
  };

  //Define today in order to only fetch data from today
  const isToday = (dateString) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date to start of the day
    const date = new Date(dateString);
    date.setHours(0, 0, 0, 0); // Normalize the compared date

    return date.getTime() === today.getTime();
  };

  // Fetch data for each table
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch Orders for Outgoing Bikes
        const ordersResponse = await axios.get("/api/orders");
        const filteredOrders = ordersResponse.data.filter(
          (order) =>
            (order.orderStatus === "new" ||
              order.orderStatus === "ready for customer") &&
            isToday(order.orderTagDate)
        );
        setOutgoingOrdersData(filteredOrders);

        // Fetch Bookings for Incoming and Outgoing Bikes
        const bookingsResponse = await axios.get("/api/bookings");
        const incomingBookings = bookingsResponse.data.filter(
          (booking) =>
            booking.bookingStatus === "new" && isToday(booking.bookingDate)
        );
        const outgoingBookings = bookingsResponse.data.filter(
          (booking) =>
            booking.bookingStatus === "ready for customer" &&
            isToday(booking.returnDate)
        );
        setIncomingBookingsData(incomingBookings);
        setOutgoingBookingsData(outgoingBookings);

        // Fetch Returns for Incoming Bikes
        const returnsResponse = await axios.get("/api/returns");
        const filteredReturns = returnsResponse.data.filter(
          (ret) => ret.returnStatus === "new" && isToday(ret.returnDate)
        );
        setIncomingReturnsData(filteredReturns);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Outgoing Orders Table Instance
  const outgoingOrdersTableInstance = useTable(
    {
      columns: outgoingOrdersColumns, // Use the specific columns defined for outgoing orders
      data: outgoingOrdersData, // The data specific to outgoing orders
      initialState: { sortBy: [{ id: "orderTagDate", desc: false }] }, // Optional: initial sort state
    },
    useSortBy // Use the useSortBy hook if sorting is needed
  );

  // Incoming Bookings Table Instance
  const incomingBookingsTableInstance = useTable(
    {
      columns: incomingBookingsColumns, // Use the specific columns defined for incoming bookings
      data: incomingBookingsData, // The data specific to incoming bookings
      initialState: { sortBy: [{ id: "bookingDate", desc: false }] }, // Optional: initial sort state
    },
    useSortBy
  );

  // Outgoing Bookings Table Instance
  const outgoingBookingsTableInstance = useTable(
    {
      columns: outgoingBookingsColumns, // Use the specific columns defined for outgoing bookings
      data: outgoingBookingsData, // The data specific to outgoing bookings
      initialState: { sortBy: [{ id: "returnDate", desc: false }] }, // Optional: initial sort state
    },
    useSortBy
  );

  // Incoming Returns Table Instance
  const incomingReturnsTableInstance = useTable(
    {
      columns: incomingReturnsColumns, // Use the specific columns defined for incoming returns
      data: incomingReturnsData, // The data specific to incoming returns
      initialState: { sortBy: [{ id: "returnDate", desc: false }] }, // Optional: initial sort state
    },
    useSortBy
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex-grow p-4 mx-6 h-full">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Outgoing Bikes Table (New and Ready-for-customer Orders) */}
        <div className="flex flex-col w-full h-full">
          <h2 className="text-xl font-semibold mt-2 mb-4 text-center">
            Outgoing Bikes - New orders
          </h2>
          <div className="overflow-auto max-h-[calc(100vh-60vh)] min-h-[calc(100vh-60vh)] shadow shadow-black rounded-lg">
            <table
              {...outgoingOrdersTableInstance.getTableProps()}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-joule text-white sticky top-0 z-10">
                {outgoingOrdersTableInstance.headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column, index) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        className={`px-2 py-4 text-xs text-left font-medium text-white uppercase tracking-wider ${
                          index === 0 ? "rounded-tl-lg" : ""
                        } ${
                          index === headerGroup.headers.length - 1
                            ? "rounded-tr-lg"
                            : ""
                        }`}
                      >
                        {column.render("Header")}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? " 🔽"
                              : " 🔼"
                            : ""}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody
                {...outgoingOrdersTableInstance.getTableBodyProps()}
                className="bg-white divide-y divide-gray-200"
              >
                {outgoingOrdersTableInstance.rows.map((row) => {
                  outgoingOrdersTableInstance.prepareRow(row);
                  const rowDateStatus = compareDateToToday(
                    row.original.orderTagDate
                  );
                  const rowClassName =
                    rowDateStatus === "today"
                      ? "bg-yellow-500 bg-opacity-50"
                      : rowDateStatus === "past"
                      ? "bg-red-500 bg-opacity-50"
                      : "";
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={`hover:bg-gray-100 ${rowClassName}`}
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className="px-2 py-4 text-sm whitespace-nowrap"
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/* Incoming Bikes Table (New Service Bookings) */}
        <div className="flex flex-col w-full h-full">
          <h2 className="text-xl font-semibold mt-2 mb-4 text-center">
            Incoming Bikes - Service
          </h2>
          <div className="overflow-auto max-h-[calc(100vh-60vh)] min-h-[calc(100vh-60vh)] shadow shadow-black rounded-lg">
            <table
              {...incomingBookingsTableInstance.getTableProps()}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-joule text-white sticky top-0 z-10">
                {incomingBookingsTableInstance.headerGroups.map(
                  (headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={`px-2 py-4 text-xs text-left font-medium text-white uppercase tracking-wider ${
                            index === 0 ? "rounded-tl-lg" : ""
                          } ${
                            index === headerGroup.headers.length - 1
                              ? "rounded-tr-lg"
                              : ""
                          }`}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  )
                )}
              </thead>
              <tbody
                {...incomingBookingsTableInstance.getTableBodyProps()}
                className="bg-white divide-y divide-gray-200"
              >
                {incomingBookingsTableInstance.rows.map((row) => {
                  incomingBookingsTableInstance.prepareRow(row);
                  const rowDateStatus = compareDateToToday(
                    row.original.bookingDate
                  );
                  const rowClassName =
                    rowDateStatus === "today"
                      ? "bg-yellow-500 bg-opacity-50"
                      : rowDateStatus === "past"
                      ? "bg-red-500 bg-opacity-50"
                      : "";
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={`hover:bg-gray-100 ${rowClassName}`}
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className="px-2 py-4 text-sm whitespace-nowrap"
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>

        {/* Incoming Bikes Table (New Returns) */}
        <div className="flex flex-col w-full h-full">
          <h2 className="text-xl font-semibold mt-2 mb-4 text-center">
            Incoming Bikes - Returns
          </h2>
          <div className="overflow-auto max-h-[calc(100vh-60vh)] min-h-[calc(100vh-60vh)] shadow shadow-black rounded-lg">
            <table
              {...incomingReturnsTableInstance.getTableProps()}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-joule text-white sticky top-0 z-10">
                {incomingReturnsTableInstance.headerGroups.map(
                  (headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={`px-2 py-4 text-xs text-left font-medium text-white uppercase tracking-wider ${
                            index === 0 ? "rounded-tl-lg" : ""
                          } ${
                            index === headerGroup.headers.length - 1
                              ? "rounded-tr-lg"
                              : ""
                          }`}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  )
                )}
              </thead>
              <tbody
                {...incomingReturnsTableInstance.getTableBodyProps()}
                className="bg-white divide-y divide-gray-200"
              >
                {incomingReturnsTableInstance.rows.map((row) => {
                  incomingReturnsTableInstance.prepareRow(row);
                  const rowDateStatus = compareDateToToday(
                    row.original.returnDate
                  );
                  const rowClassName =
                    rowDateStatus === "today"
                      ? "bg-yellow-500 bg-opacity-50"
                      : rowDateStatus === "past"
                      ? "bg-red-500 bg-opacity-50"
                      : "";
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={`hover:bg-gray-100 ${rowClassName}`}
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className="px-2 py-4 text-sm whitespace-nowrap"
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* Modal for Return Confirmation */}
          {returnModalData && (
            <ReturnConfirmationModal
              isOpen={!!returnModalData}
              data={returnModalData}
              source={returnModalData.source}
              toggleModalVisibility={() => toggleReturnModal()}
              markAsReturned={markAsReturned}
              markAsRepaired={markAsRepaired}
            />
          )}
        </div>

        {/* Outgoing Bikes Table (Ready-for-customer Service Bookings) */}
        <div className="flex flex-col w-full h-full">
          <h2 className="text-xl font-semibold mt-2 mb-4 text-center">
            Outgoing Bikes - Service
          </h2>
          <div className="overflow-auto max-h-[calc(100vh-60vh)] min-h-[calc(100vh-60vh)] shadow shadow-black rounded-lg">
            <table
              {...outgoingBookingsTableInstance.getTableProps()}
              className="min-w-full divide-y divide-gray-200"
            >
              <thead className="bg-joule text-white sticky top-0 z-10">
                {outgoingBookingsTableInstance.headerGroups.map(
                  (headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column, index) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          className={`px-2 py-4 text-xs text-left font-medium text-white uppercase tracking-wider ${
                            index === 0 ? "rounded-tl-lg" : ""
                          } ${
                            index === headerGroup.headers.length - 1
                              ? "rounded-tr-lg"
                              : ""
                          }`}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  )
                )}
              </thead>
              <tbody
                {...outgoingBookingsTableInstance.getTableBodyProps()}
                className="bg-white divide-y divide-gray-200"
              >
                {outgoingBookingsTableInstance.rows.map((row) => {
                  outgoingBookingsTableInstance.prepareRow(row);
                  const rowDateStatus = compareDateToToday(
                    row.original.returnDate
                  );
                  const rowClassName =
                    rowDateStatus === "today"
                      ? "bg-yellow-500 bg-opacity-50"
                      : rowDateStatus === "past"
                      ? "bg-red-500 bg-opacity-50"
                      : "";
                  return (
                    <tr
                      {...row.getRowProps()}
                      className={`hover:bg-gray-100 ${rowClassName}`}
                    >
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          className="px-2 py-4 text-sm whitespace-nowrap"
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BikeFlowDashboard;
