import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import axios from "axios";
import { format } from "date-fns";
import Table from "../components/tableComponent";
import SendMailModal from "../components/sendMailModal"; // Import the SendMailModal component

const MissingItemsTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mailModalData, setMailModalData] = useState(null); // State for mail modal data
  const [filterInput, setFilterInput] = useState(""); // User's input for filtering
  const [filterCategory, setFilterCategory] = useState("");
  const [filterOpenItems, setFilterOpenItems] = useState(true); // State to track the checkbox

  const toggleMailModal = useCallback((data = null) => {
    if (data) {
      setMailModalData(data); // Set the data for the modal
    } else {
      setMailModalData(null); // Hide the modal
    }
  }, []);

  const updateField = useCallback(
    async (id, field, value) => {
      try {
        const response = await axios.put(`/api/missing-items/${id}`, {
          [field]: value,
        });
        if (response.status === 200) {
          const updatedData = data.map((item) =>
            item._id === id ? { ...item, [field]: value } : item
          );
          setData(updatedData);
        }
      } catch (error) {
        console.error(`Error updating ${field}:`, error);
      }
    },
    [data]
  );

  const closeCase = useCallback(
    async (id) => {
      await updateField(id, "missingItemStatus", "closed");
    },
    [updateField]
  );

  const columns = useMemo(
    () => [
      {
        Header: "Created",
        accessor: "createdAt",
        Cell: ({ value }) => format(new Date(value), "dd.M.yyyy"), // Format date as dd-mm-yy
      },
      { Header: "Serial Number", accessor: "serialNumber" },
      { Header: "Full Name", accessor: "fullName" },
      { Header: "Phone", accessor: "phone" },
      { Header: "Email", accessor: "email" },
      { Header: "Item SKU Name", accessor: "itemSkuName" },
      {
        Header: "Missing Items",
        accessor: "missingItems",
        Cell: ({ value }) => value.join(", "),
      },
      { Header: "Note", accessor: "note" },
      { Header: "Status", accessor: "missingItemStatus" },
      {
        Header: "Actions",
        id: "actions",
        Cell: ({ row }) => (
          <>
            {row.original.missingItemStatus === "open" && (
              <button
                onClick={() => toggleMailModal(row.original)}
                className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline mr-2"
              >
                Send Mail
              </button>
            )}
            {row.original.missingItemStatus !== "closed" && (
              <button
                onClick={() => closeCase(row.original._id)}
                className="bg-joule hover:bg-gray-900 text-white py-1 px-2 text-sm rounded focus:outline-none focus:shadow-outline"
              >
                Close Case
              </button>
            )}
          </>
        ),
      },
    ],
    // closeCase is now a stable function so it can be safely included here
    [toggleMailModal, closeCase]
  );

  useEffect(() => {
    const fetchMissingItems = async () => {
      try {
        const response = await axios.get("/api/missing-items");
        setData(response.data || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching missing items:", error);
        setLoading(false);
      }
    };

    fetchMissingItems();
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    setFilter,
    rows,
    state: { pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: [{ id: "createdAt", desc: true }],
        filters: [{ id: "missingItemStatus", value: "open" }], // Set initial filter to show only open cases
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  // Handle changes in filter input
  const handleFilterChange = (e) => {
    setFilterInput(e.target.value);
    if (filterCategory) {
      setFilter(filterCategory, e.target.value);
    }
  };

  const handleFilterOpenItemsChange = (e) => {
    setFilterOpenItems(e.target.checked);
    if (e.target.checked) {
      setFilter("missingItemStatus", "open"); // Set filter to show only 'open' items
    } else {
      setFilter("missingItemStatus", ""); // Reset the filter when checkbox is unchecked
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex-grow p-4 mx-6">
      <h2 className="text-2xl font-semibold mb-4">Missing Items</h2>

      {/* Filter Inputs */}
      <div className="flex justify-between mb-4 items-center">
        {/* Dropdown to select filter category/column */}
        <select
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value)}
          className="border p-2 rounded mr-2 focus:outline-none focus:ring-2 focus:ring-indigo-400"
        >
          <option value="">Select Filter Category...</option>
          {columns.map((column) => (
            <option value={column.accessor} key={column.accessor}>
              {column.Header}
            </option>
          ))}
        </select>

        <label className="flex items-center space-x-2 bg-gray-200 px-3 py-1 rounded-lg cursor-pointer">
          <input
            type="checkbox"
            checked={filterOpenItems}
            onChange={handleFilterOpenItemsChange}
            className="form-checkbox text-black rounded border-gray-300 bg-white focus:border-joule focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          />
          <span className="text-sm font-medium text-gray-700">
            Show only open cases
          </span>
        </label>
      </div>

      {/* Text input for entering filter values */}
      <input
        value={filterInput}
        onChange={handleFilterChange}
        placeholder={"Enter filter value..."}
        className="border p-2 mt-4 rounded w-full focus:outline-none focus:ring-2 focus:ring-indigo-400"
      />

      <Table
        columns={columns}
        data={page}
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        nextPage={nextPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalFilteredRows={rows.length}
      />
      {mailModalData && (
        <SendMailModal
          isOpen={!!mailModalData}
          closeModal={() => toggleMailModal(null)}
          rowData={mailModalData}
          onStatusUpdate={(id, newStatus) =>
            updateField(id, "missingItemStatus", newStatus)
          }
        />
      )}
    </div>
  );
};

export default MissingItemsTable;
